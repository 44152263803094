import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

const routes = [
    {
        path: "/",
        name: "Main",
        component: (resolve) => require(['@/views/main'], resolve),
        children: [
            {
                path: "/home",
                name: "Home",
                component: (resolve) => require(['@/views/home'], resolve)
            },
            {
                path: "/product",
                name: "Product",
                component: (resolve) => require(['@/views/product'], resolve)
            },
            {
                path: "/solution",
                name: "Solution",
                component: (resolve) => require(['@/views/solution'], resolve)
            },
            {
                path: "/companyDynamics",
                name: "CompanyDynamics",
                component: (resolve) => require(['@/views/companyDynamics'], resolve)
            },
            {
                path: "/serviceSupport",
                name: "ServiceSupport",
                component: (resolve) => require(['@/views/serviceSupport'], resolve)
            },
            {
                path: "/aboutUs",
                name: "AboutUs",
                component: (resolve) => require(['@/views/aboutUs'], resolve)
            },
            //   移动端页面
            {
                path: "/mobile/home",
                name: "MobildeHome",
                component: (resolve) => require(['@/views/mobile/home'], resolve)
            },
            {
                path: "/mobile/product",
                name: "MobildeProduct",
                component: (resolve) => require(['@/views/mobile/product'], resolve)
            },
            {
                path: "/mobile/solution",
                name: "MobildeSolution",
                component: (resolve) => require(['@/views/mobile/solution'], resolve)
            },
            {
                path: "/mobile/companyDynamics",
                name: "MobildeCompanyDynamics",
                component: (resolve) => require(['@/views/mobile/companyDynamics'], resolve)
            },
            {
                path: "/mobile/serviceSupport",
                name: "MobildeServiceSupport",
                component: (resolve) => require(['@/views/mobile/serviceSupport'], resolve)
            },
            {
                path: "/mobile/aboutUs",
                name: "MobildeAboutUs",
                component: (resolve) => require(['@/views/mobile/aboutUs'], resolve)
            }
        ]
    },
    // 邮箱免责声明页面
    {
        path: "/statements",
        name: "Statements",
        component: (resolve) => require(['@/views/statements'], resolve)
    },
    // 公众号公司简介页面
    {
        path: "/officialAccount",
        name: "OfficialAccount",
        component: (resolve) => require(['@/views/officialAccount'], resolve)
    }
];


export default new Router({
    mode: 'history',
    routes
})
