import Vue from 'vue'
import Element from 'element-ui'
import App from './App.vue'
import router from './router/routers'
import './router/index'

// 样式导入
import '@/assets/styles/index.css'
import 'element-ui/lib/theme-chalk/index.css';

import imageSrc from './assets/js/imageSrc'
Vue.use(imageSrc)

import VueTouch from 'vue-touch'
Vue.use(VueTouch, { name: 'v-touch' })

Vue.use(Element, {
  size: 'small' // set element-ui default size
})

Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
