import Router from './routers'

Router.beforeEach((to, from, next) => {
    // 错误路由都定位到首页
    if (to.matched.length === 0) {
        // 页面回到顶部
        document.documentElement.scrollTop = 0
        next('/')
    } else {
        // 页面回到顶部
        document.documentElement.scrollTop = 0
        next();
    }
})