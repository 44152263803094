export default {
    install(Vue) {
        Vue.prototype.imageSrc = (src) => {
            if (src) {
                const matchStr = '/file/pictures'
                const strList = src.split(matchStr)
                const newSrc = matchStr + strList[strList.length - 1]
                return newSrc
            } else {
                return ''
            }
        }
    }
}